<template>
  <div class="card-holder__body-row df-fc">
    <ul class="validation-errors" v-if="errors.length > 0 && showErrors">
      <li :key="e" v-for="e in errors">{{ e }}</li>
    </ul>
    <label for="" :class="labelClass">
      {{ name }}
      <span v-if="required" class="c-c-danger">*</span>
    </label>
    <textarea v-if="textarea" class="c-input c-textarea c-textarea-n-resize"
              :class="{'unvalidate': validationFailed}"
              @change="change"
              :disabled="disabled"
              :value="value"></textarea>
    <NumberInput v-else-if="number"
                 :input-class="{'c-input': true, 'unvalidate': validationFailed}"
                 @change="change"
                 :placeholder="placeholder"
                 :disabled="disabled"
                 :rounded="rounded"
                 :spaces="spaces"
                 :float-format="floatFormat"
                 v-model="valueNumber"
    />
    <select v-else-if="select" v-model="value"
            @change="change" class="c-input c-select" :disabled="disabled"
            :class="{'unvalidate': validationFailed}">
      <option v-if="unselect !== ''" :value="unselectValue">{{unselect}}</option>
      <option :value="i[itemKey]"
              :key="i[itemKey]" v-for="i in items">
        {{ i[itemName] }}
      </option>
    </select>
    <input v-else-if="!$slots.field" @change="change" class="c-input c-input--c-black"
           :placeholder="placeholder"
           :class="{'unvalidate': validationFailed}"
           :disabled="disabled" v-model="value"/>
    <slot name="field" :validationFailed="validationFailed"></slot>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'ValidateInput',
  props: {
    textarea: Boolean,
    select: Boolean,
    number: Boolean,
    required: Boolean,
    disabled: Boolean,
    rounded: Boolean,
    spaces: Boolean,
    placeholder: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    labelClass: {
      type: String,
      default: 'card-holder-label',
    },
    unselect: String,
    unselectValue: String,
    showErrors: Boolean,
    items: {
      type: Array,
    },
    itemKey: {
      type: String,
      default: '',
    },
    itemName: {
      type: String,
      default: '',
    },
    floatFormat: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      default: '',
    }
  },
  data() {
    return {
      value: '',
      valueNumber: 0,
      validationFailed: false,
      isSett: false,
      validationList: {
        required: 'Поле обязательно для заполнения',
      }
    }
  },
  emits: [
    'update:modelValue',
  ],
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (this.number) {
          this.valueNumber = newValue
        } else {
          if (this.isSett) {
            this.setValue(newValue)
          } else {
            this.value = newValue
          }
        }
        this.isSett = true
      },
    },
    errors: {
      immediate: true,
      handler() {
        if (this.$props.errors.length > 0) {
          this.validationFailed = true
        } else {
          this.validationFailed = false
        }
      }
    }
  },
  methods: {
    change(event) {
      if (this.number) {
        this.setValueNumber(this.valueNumber)
      } else {
        this.setValue(event.target.value)
      }
    },
    setValue(newValue) {
      let oldValue = this.value
      this.value = newValue

      if (this.required && newValue === '') {
        this.$props.errors.push(this.validationList.required)
      } else {
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
      }

      this.$emit('update:modelValue', newValue, oldValue)
    },
    setValueNumber(newValue) {
      if (this.required && !newValue) {
        this.$props.errors.push(this.validationList.required)
      } else {
        let index = this.$props.errors.indexOf(this.validationList.required)
        if (index !== -1) {
          this.$props.errors.splice(index, 1)
        }
      }
      this.$emit('update:modelValue', newValue, newValue)
    }
  }
})
</script>

<style scoped>

</style>