<script setup async>
import Pagination from "@/components/partial/Pagination.vue";
import {onMounted, ref, computed, watch, onUnmounted} from "vue";
import {PurchaseService} from "@/common/api.service";
import {useStore} from "vuex";
import {useRoute, useRouter} from 'vue-router';
import shared from "@/common/shared";
import {useToast} from "vue-toastification";
import moment from "moment";
import JwtService from "@/common/jwt.service";

const route = useRoute()
const router = useRouter()
const store = useStore()
const toast = useToast()
const list = ref({
  columns: [],
  directories: {},
  meta: {
    total: 0,
    per_page: 0,
    total_filtered: 0,
    IS_CAN_EXPORT: false,
    EXPENSES_SUM: 0,
    COST_SUM: 0,
  }
});
const currentPage = ref(1)
const currentLimit = ref(10)
const request = ref({
  is_initial_load: 1,
  page: currentPage.value,
  limit: currentLimit.value,
  filters: {
    CREATED_BY: [],
    STATUS: [],
    PM: [],
    PLACEMENT: [],
    ID: [],
  }
})
const toggle = ref(true)
const checked = ref([])
const newStatus = ref(0)
const createPurchaseData = ref({
  UF_SUBJECT: "",
  UF_COST: Number.NaN,
  UF_IS_PLANNED_THIRD_OR_LAST: false,
  UF_QUANTITY: "",
  UF_EI_ID: "",
  UF_PM_ID: "",
  pdm: "",
  pdy: "",
  edm: "",
  edy: "",
})
const reportData = ref({
  UF_YEAR: moment().toDate().getFullYear(),
  ID: 0,
  FORMAT: ""
})
const subLines = ref({})
const createDisable = ref(false)
const planActiveId = ref(0)

await store.dispatch("fetchDirectory", {
  'ei': 'simple',
  'purchase_plan_methods': 'simple',
}).catch(async () => {
  await JwtService.destroyToken()
  await router.push({
    name: "Auth"
  })
});

if (route.query._r) {
  request.value = JSON.parse(route.query._r)
  currentPage.value = request.value.page
  currentLimit.value = request.value.limit
}

async function fetchList() {
  await PurchaseService.list(request.value)
      .then(({data}) => {
        list.value = data
        createPurchaseData.value.UF_PLAN_ID = list.value.meta.PLAN_LIST[0].ID
        reportData.value.ID = list.value.meta.REPORT_LIST[0].ID
        if (data.order) {
          request.value.order = data.order
        }
        checked.value = []
        request.value.filters = data.filters
        if (!request.value.filters.CREATED_BY) {
          request.value.filters.CREATED_BY = []
        }
        if (!request.value.filters.STATUS) {
          request.value.filters.STATUS = []
        }
        if (!request.value.filters.PM) {
          request.value.filters.PM = []
        }
        if (!request.value.filters.ID) {
          request.value.filters.ID = []
        }
        if (!request.value.filters.UF_PLACEMENT_YEAR) {
          request.value.filters.UF_PLACEMENT_YEAR = ""
        }
        if (!request.value.filters.UF_PLANNED_DOGOVOR_YEAR) {
          request.value.filters.UF_PLANNED_DOGOVOR_YEAR = ""
        }
        if (!request.value.filters.EXPENSE_UF_YEAR) {
          request.value.filters.EXPENSE_UF_YEAR = ""
        }
        if (!request.value.filters.PLACEMENT) {
          request.value.filters.PLACEMENT = []
        }
        request.value.order_empty = 1
        delete request.value.is_initial_load
        router.push({
          query: {
            _r: JSON.stringify(request.value)
          }
        })
      }).catch(() => {
      })
}

await fetchList()

const columnList = computed(() => {
  return list.value.columns.filter((el) => el.visible)
})
const availableIDList = computed(() => {
  let r = []
  list.value.meta.ALL.forEach(function (f) {
    r.push({value: f.ID, label: f.ID})
  })

  return r
})

const availableNumberList = computed(() => {
  let r = []
  list.value.meta.ALL.forEach(function (f) {
    r.push({value: f.UF_NUMBER, label: (f.UF_NUMBER)?f.UF_NUMBER:f.ID})
  })

  return r
})

const availablePlanList = computed(() => {
  let r = []
  list.value.meta.PLAN_LIST.forEach(function (f) {
    r.push({value: f.ID, label: f.UF_YEAR})
  })

  return r
})
const createdByList = computed(() => {
  let r = []
  list.value.meta.CREATED_BY_LIST.forEach(function (f) {
    r.push({value: f.ID, label: f.FULL_NAME})
  })

  return r
})
const statusList = computed(() => {
  let r = []
  list.value.meta.STATUS_FILTER_ITEMS.forEach(function (f) {
    r.push({value: f.ID, label: f.UF_NAME})
  })

  return r
})
const pmList = computed(() => {
  let r = []
  list.value.directories.purchase_plan_methods.forEach(function (f) {
    r.push({value: f.ID, label: f.UF_NAME})
  })

  return r
})
const eiList = computed(() => {
  let r = []
  store.state.directory.simple.ei.forEach(function (f) {
    r.push({value: f.ID, label: `${f.UF_CODE} - ${f.UF_NAME}`})
  })

  return r
})
const pages = computed(() => {
  if (
      !list.value.meta.total_filtered ||
      list.value.meta.total_filtered <= list.value.meta.per_page
  ) {
    return [];
  }
  return [
    ...Array(Math.ceil(list.value.meta.total_filtered / list.value.meta.per_page)).keys()
  ].map(e => e + 1);
})
const checkAll = computed({
  get: function () {
    return list.value.data ? list.value.length === checked.value.length : false;
  },
  set: function (value) {
    let ch = []

    if (value) {
      list.value.data.forEach(function (item) {
        ch.push(item.ID);
      });
    }

    checked.value = ch;
  }
})
const chPlanStatuses = computed(() => {
  return [list.value.directories.purchase_plan_statuses.find(el => (el.UF_CODE === 'on_approval_zgd')), list.value.directories.purchase_plan_statuses.find(el => (el.UF_CODE === 'included_in_the_purchase_plan'))]
})
const checkedList = computed(() => {
  let r = []
  checked.value.forEach(function (f) {
    r.push("№" + f)
  })

  return r.join(", ")
})

watch(currentPage, async () => {
  request.value.page = currentPage.value
  await fetchList()
})

watch(currentLimit, async () => {
  request.value.limit = currentLimit.value
  await fetchList()
})

async function changeOrder(f) {
  if (!request.value.order[f]) {
    request.value.order[f] = 'asc'
  } else if (request.value.order[f] === 'asc') {
    request.value.order[f] = 'desc'
  } else {
    delete request.value.order[f]
  }
  await fetchList()
}

function field(f) {
  let r = list.value.columns.find(el => el.name === f)
  if (r) {
    return r
  }
  return {}
}

async function changeStatuses() {
  await PurchaseService.bulkStatusChange({
    UF_STATUS_ID: newStatus.value,
    IDS: checked.value,
  })
      .then(async ({data}) => {
        if (data.length) {
          data.forEach((e) => {
            if (!e.IS_OK && e.ERROR) {
              toast.error(e.ERROR, {
                timeout: 5000
              });
            }
          })
        }
        await fetchList()
        shared.closeModal('#modal-edit-status')
      }).catch(() => {

      })
}

async function bulkApprove() {
  await PurchaseService.bulkApprove({
    IDS: checked.value,
  })
      .then(async ({data}) => {
        if (data.length) {
          data.forEach((e) => {
            if (!e.IS_OK && e.ERROR) {
              toast.error(e.ERROR, {
                timeout: 5000
              });
            }
          })
        }
        await fetchList()
        shared.closeModal('#modal-approve')
      }).catch(() => {

      })
}

async function createPurchase() {
  createDisable.value = true
  createPurchaseData.value.PLANNED_DOGOVOR = `${createPurchaseData.value.pdm}.${createPurchaseData.value.pdy}`
  //createPurchaseData.value.PLACEMENT_DATE = `${createPurchaseData.value.pdm}.${createPurchaseData.value.pdy}`
  createPurchaseData.value.EXECUTION_DATE = `${createPurchaseData.value.edm}.${createPurchaseData.value.edy}`
  await PurchaseService.create(createPurchaseData.value)
      .then(async ({data}) => {
        shared.closeModal('#modal-purchase-plan')
        createDisable.value = false
        await router.push({
          name: "CardPurchase",
          params: {id: data.ID}
        });
      }).catch(({response}) => {
        createDisable.value = false
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

async function exportExcel() {
  await PurchaseService.report(reportData.value.ID, reportData.value)
      .then(async ({data}) => {
        document.location.href = data.url
        shared.closeModal('#make-file')
      }).catch(({response}) => {
        toast.error(response.data.message, {
          timeout: 5000
        });
      })
}

async function resetFilter() {
  request.value.filters = {}
  await fetchList()
}

/*function showDeleteCardPlan(id) {
  planActiveId.value = id;
  shared.showModal("#modal-purchase-plan-delete")
}*/

async function deletePlanCard() {
  shared.closeModal("#modal-purchase-plan-delete")
  await PurchaseService.delete(planActiveId.value).then(async() => {
    toast.success("Закупка удалена", {
      timeout: 1000
    })
    currentPage.value = 1;
    await fetchList()
  }).catch(({response}) => {
    toast.error(response.data.message, {
      timeout: 5000
    })
  })
}

function showedSub(i) {
  if (!subLines.value[i]) {
    subLines.value[i] = false
  }
  return subLines.value[i]
}

function showSub(i) {
  if (!showedSub(i)) {
    subLines.value[i] = true
  } else {
    subLines.value[i] = false
  }
}

/** пока не удаляем!!!
 function handleMultiselectTagClick() {
 document.addEventListener('click', function (event) {
 if (!event.target.classList.contains('multiselect-tag')) {
 const parent = event.target.closest('.multiselect-tags');

 if (!parent) {
 const multiselectTags = document.querySelectorAll('.multiselect-tags');
 multiselectTags.forEach(function (tag) {
 tag.classList.remove('show');
 });
 }
 } else {
 const multiselectTags = document.querySelectorAll('.multiselect-tags');
 multiselectTags.forEach(function (tag) {
 if (tag !== event.target.parentElement) {
 tag.classList.remove('show');
 }
 });
 event.target.parentElement.classList.toggle('show');
 }
 });
 }*/


function handleMultiselectTagClick() {
  document.addEventListener('click', function (event) {
    const multiselectTags = document.querySelectorAll('.multiselect-tags');

    multiselectTags.forEach(function (tag) {
      if (tag !== event.target.closest('.multiselect-tags')) {
        tag.classList.remove('show');
      }
    });

    const parent = event.target.closest('.multiselect-tags');
    if (parent) {
      parent.classList.toggle('show');
    }
  });
}

function selectPlaceholderColor() {

  let selects = document.querySelectorAll('select.c-select');

  selects.forEach(select => {
    // Добавляем слушатель события изменения значения
    select.addEventListener('change', function () {

      if (this.value === '') {
        this.style.color = '';
      } else {
        this.style.color = '#6C6D70';
      }
    });
  })

}

onMounted(async () => {
  await router.isReady()
  handleMultiselectTagClick()
  selectPlaceholderColor()
})
onUnmounted(() => {
  handleMultiselectTagClick()
  selectPlaceholderColor()
})
</script>

<template>
  <div class="main-wraper" id="js-main-wraper">
    <MainHeader></MainHeader>
    <!-- header -->
    <section class="content-wrapper" id="js-content-wrapper">
      <div class="container-fluid">
        <section class="page-title-container">
          <div class="page-title-container__col">
            <div class="page-breadcrumbs"><span class="stl-fw-600">План закупок</span>
            </div>
            <span
                :class="{'holder-title__attribute': list.meta.IS_ACTIVE_PERIOD_CHANGE}">{{
                list.meta.PERIOD_TEXT_CHANGE
              }}</span>
            <div class="page-title-aside df-ac" v-if="list.meta.IS_VIEW_COST_SUM">
                            <span class="page-title-aside__item" v-if="list.meta.EXPENSES_SUM!==''">
                                <span class="stl-fw-600">Сумма (НМЦ) по году, руб.</span>: {{
                                list.meta.EXPENSES_SUM
                              }} </span>
              <span class="page-title-aside__item"
                    v-if="list.meta.COST_SUM!==''">
                                <span class="stl-fw-600">Сумма (НМЦД) по договору, руб.</span>: {{
                  list.meta.COST_SUM
                }} </span>
            </div>
          </div>
          <div class="page-title-container__col page-title-container__controll"
               v-if="list.meta.IS_CAN_EXPORT">
            <a @click="shared.showModal('#make-file')" class="btn btn-blue">Сформировать файл</a>
          </div>
        </section>
        <!-- page-title-container -->
        <section class="box-stl c-filter--container">
          <div class="text-center" id="js-filter-show"><span class="c-filter--mob-link stl-tt-up"
                                                             onclick="filterMobileShow();">Развернуть фильтр</span>
          </div>
          <div class="c-filter--grid df-jsb df-none-wrap df-al" id="js-filter-grid">
            <div class="c-filter--filter-area">
              <div class="c-filter__item">
                <Multiselect
                    placeholder="ID плана"
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    :disabled="!field('PLAN').searchable"
                    v-model="request.filters.PLAN"
                    mode="tags"
                    :searchable="true"
                    :options="availablePlanList"
                />
              </div>
              <div class="c-filter__item"  v-if="field('ID').searchable">
                <Multiselect
                    placeholder="ID карточки"
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    v-model="request.filters.ID"
                    mode="tags"
                    :searchable="true"
                    :options="availableNumberList"
                />
              </div>
              <div class="c-filter__item" v-if="field('UF_NUMBER').searchable">
                <Multiselect
                    placeholder="№ карточки"
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    v-model="request.filters.UF_NUMBER"
                    mode="tags"
                    :searchable="true"
                    :options="availableIDList"
                />
              </div>
              <div class="c-filter__item" v-if="field('UF_SUBJECT').searchable">
                <input type="text" class="c-input c-filter__input" v-model="request.filters.UF_SUBJECT"
                       placeholder="Предмет закупки ">

              </div>
              <div class="c-filter__item">
                <Multiselect
                    placeholder="Инициатор"
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    :disabled="!field('CREATED_BY').searchable"
                    v-model="request.filters.CREATED_BY"
                    mode="tags"
                    :searchable="true"
                    :options="createdByList"
                />
              </div>
              <div class="c-filter__item">
                <Multiselect
                    placeholder="Статус закупки в плане"
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    v-model="request.filters.STATUS"
                    mode="tags"
                    :searchable="true"
                    :options="statusList"
                />
              </div>
              <div class="c-filter__item">
                <Multiselect
                    placeholder="Способ закупки"
                    class="c-input c-select c-filter__input c-multiselect-filter"
                    v-model="request.filters.PM"
                    mode="tags"
                    :searchable="true"
                    :options="pmList"
                />
              </div>
              <div class="c-filter__item c-filter__item-md c-filter__item-data-inp"
                   v-if="field('PLACEMENT').searchable">
                <VueDatePicker v-model="request.filters.PLACEMENT" format="MM.yyyy" :month-picker="true"
                               multi-dates
                               model-type="format"
                               :placeholder="'Планируемый срок размещения'">
                </VueDatePicker>
              </div>
              <div class="c-filter__item c-filter__item-md c-filter__item-data-inp"
                   v-if="field('UF_DEADLINE_DOCUMENTS').searchable">
                <VueDatePicker v-model="request.filters.UF_DEADLINE_DOCUMENTS" format="MM.yyyy" :month-picker="true"
                               multi-dates
                               model-type="format"
                               :placeholder="'Срок предоставления документов'">
                </VueDatePicker>
              </div>
              <div class="c-filter__item" v-if="field('UF_PLACEMENT_YEAR').searchable">
                <select v-model="request.filters.UF_PLACEMENT_YEAR"
                        class="c-input c-select c-filter__input">
                  <option value="">Год планирования</option>
                  <option :value="i" :key="i" v-for="i in list.meta.EXPENSE_YEAR_LIST">
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class="c-filter__item" v-if="field('UF_PLANNED_DOGOVOR_YEAR').searchable">
                <select v-model="request.filters.UF_PLANNED_DOGOVOR_YEAR"
                        class="c-input c-select c-filter__input">
                  <option value="">Год планирования подписания договора</option>
                  <option :value="i" :key="i" v-for="i in list.meta.EXPENSE_YEAR_LIST">
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class="c-filter__item" v-if="field('EXPENSE_UF_YEAR').searchable">
                <select v-model="request.filters.EXPENSE_UF_YEAR"
                        class="c-input c-select c-filter__input">
                  <option value="">Год расхода</option>
                  <option :value="i" :key="i" v-for="i in list.meta.EXPENSE_YEAR_LIST">
                    {{ i }}
                  </option>
                </select>
              </div>
              <div class="c-filter__controll">
                <div class="btn btn-reset" @click="resetFilter">СБРОС</div>
                <div class="btn btn-blue" @click="fetchList">Применить</div>
              </div>
            </div>
            <div class="df-ac c-filter--control-toggle" v-if="field('IS_PARENTS_HIDDEN').searchable">
              <label @click="toggle=!toggle">Скрыть родительские карточки </label>
              <Toggle v-model="request.filters.IS_PARENTS_HIDDEN" @change="fetchList"/>
            </div>
          </div>
          <!-- c-filter--grid -->
        </section>
        <!-- c-filter--container -->
        <section class="page-container">
          <div class="box-stl c-table--container">
            <div class="holder-responsive">
              <table class="c-table">
                <thead>
                <tr>
                  <th :key="column.name" v-for="column in columnList">
                    <div class="sorting-holder" v-if="column.type==='column'">
                      <span>{{ column.title }}</span>
                      <span @click="changeOrder(column.name)" v-if="column.orderable"
                            class="sorting-link" :class="{
                                            'active': list.order[column.name] === 'desc',
                                            'active-up': list.order[column.name] === 'asc'
                                        }"></span>
                    </div>
                    <div class="sorting-holder" v-if="column.type==='select'">
                      <input type="checkbox" v-model="checkAll">
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody :key="item.ID" v-for="item in list.data" :class="{
                  'c-table-history-container': item.children && item.children.length > 1,
                  'active': showedSub(item.ID)
                  }">
                <tr :class="{
                  'c-table-row_active text-bold': item.children && item.children.length > 1
                  }">
                  <td :key="column.name" v-for="column in columnList">
                    <div class="df-ac">
                    <span v-if="column.type === 'icon' && item[column.key]" class="d-i-icon"
                          :class="item[column.key].type" :title="item[column.key].title"></span>

                      <!--<span v-if="item[column.key] && item[column.key].type === 'd-i-new' &&
                             list.meta.IS_CAN_CREATE &&
                             item.STATUS.UF_CODE === 'draft'"
                            class="d-i-icon c-i-delete stl-ml-5"
                            @click="showDeleteCardPlan(item.ID)"
                            title="Удалить">
                      </span>-->

                      <span
                          :class="{'active': showedSub(item.ID)}"
                          @click="showSub(item.ID)"
                          v-if="column.type==='expand_children' && item.children && item.children.length > 1"
                          class="js-link-chevron icon-chevron"></span>
                      <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                            :style="{
                      'white-space': column.modifiers.find(val => val === 'white-space-pre')?'pre':'',
                      'word-break': column.modifiers.find(val => val === 'break-all')?'break-all':''
                    }"
                      >{{
                          shared.showField(column, item)
                        }}</span>
                      <router-link
                          :to="{name: 'CardPurchase', query: { _r: route.query._r }, params: { id: item['ID']}}"
                          v-if="column.display_as === 'router_link'">
                        {{ shared.showField(column, item) }}
                      </router-link>
                      <div class="sorting-holder" v-if="column.type==='select'">
                        <input type="checkbox" :value="item.ID" v-model="checked">
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="c-table-row_hidden" :key="i2.ID" v-for="i2 in item.children"
                    :class="{'active': showedSub(item.ID)}">
                  <td :key="column.name" v-for="column in columnList">
                    <span v-if="column.display_as === 'text' || column.display_as === 'text_path'"
                          :class="{'stl-ws-pre':column.modifiers.filter(val => val === 'white-space-pre')}">{{
                        shared.showField(column, i2)
                      }}</span>
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr v-if="list.meta.IS_CAN_BULK_STATUS_CHANGE || list.meta.IS_CAN_BULK_APPROVAL">
                  <td colspan="10">
                    <button v-if="list.meta.IS_CAN_BULK_STATUS_CHANGE" type="button" class="btn btn-blue c-btn-table"
                            data-bs-toggle="modal"
                            :disabled="checked.length === 0"
                            data-bs-target="#modal-edit-status">сменить статус
                    </button>
                    <button v-if="list.meta.IS_CAN_BULK_APPROVAL" type="button" class="btn btn-blue c-btn-table"
                            data-bs-toggle="modal"
                            :disabled="checked.length === 0"
                            data-bs-target="#modal-approve">согласовать
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
              <Pagination
                  :pages="pages"
                  v-model="currentPage"
                  :total="list.meta.total_filtered"
                  :perPage="currentLimit"
                  @update:perPage="currentLimit = $event"
              ></Pagination>
            </div>
          </div>
        </section>
        <!-- page-container -->
      </div>
    </section>
    <!-- content-wrapper -->
    <MainFooter>
      <div class="footer-right__controll">
        <button v-if="list.meta.IS_CAN_CREATE" class="btn btn-green"
                @click="shared.showModal('#modal-purchase-plan')">СОЗДАТЬ
          ЗАКУПКУ В ПЛАН
        </button>
      </div>
    </MainFooter>
    <!-- footer -->
  </div>
  <!-- main-wraper -->
  <!-- Изменение статуса закупок в плане -->
  <div class="modal" id="modal-edit-status" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изменение статуса закупок в плане</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Статус закупок в плане</label>
              <select v-model="newStatus" class="c-input c-select">
                <option :value="i.ID" :key="i.ID" v-for="i in chPlanStatuses">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" data-bs-dismiss="modal">отменить</button>
          <button type="button" class="btn btn-blue" @click="changeStatuses">изменить</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Изменение статуса закупок в плане -->
  <div class="modal" id="modal-approve" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Согласование закупок в плане</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Вы действительно хотите согласовать закупки {{ checkedList }}?</label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" data-bs-dismiss="modal">отменить</button>
          <button type="button" class="btn btn-blue" @click="bulkApprove">согласовать</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Создание закупки в план -->
  <div class="modal" id="modal-purchase-plan" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Создание закупки в план</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-purchase-plan')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">План <span
                  class="c-c-danger">*</span></label>
              <div class="row">
                <div class="col-xs-12 col-sm-12">
                  <select v-model="createPurchaseData.UF_PLAN_ID" class="c-input c-select">
                    <option :value="i.ID"
                            :key="i" v-for="i in list.meta.PLAN_LIST">{{ i.UF_YEAR }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Предмет закупки <span class="c-c-danger">*</span></label>
              <textarea v-model="createPurchaseData.UF_SUBJECT"
                        class="c-input c-textarea c-textarea-n-resize"></textarea>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Цена (НМЦД), руб. <span
                  class="c-c-danger">*</span></label>
              <NumberInput
                  :input-class="{'c-input': true}"
                  v-model="createPurchaseData.UF_COST"
                  :float-format="2"
              />
              <div style="padding-top: 10px">
                <label>
                  <input type="checkbox"
                         v-model="createPurchaseData.UF_IS_PLANNED_THIRD_OR_LAST"
                  >
                  Закупка запланирована на третий или последующие годы реализации плана закупки
                </label>
              </div>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Количество <span class="c-c-danger">*</span></label>
              <NumberInput
                  :input-class="{'c-input': true}"
                  v-model="createPurchaseData.UF_QUANTITY"
                  :float-format="store.getters.simpleItem('ei', createPurchaseData.UF_EI_ID).UF_VALUE_PRECISION"
              />
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Ед. измерения (код по ОКЕИ) <span
                  class="c-c-danger">*</span></label>
              <Multiselect
                  class="c-input c-select c-filter__input c-multiselect-filter"
                  v-model="createPurchaseData.UF_EI_ID"
                  mode="single"
                  :searchable="true"
                  :options="eiList"
              />
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Планируемый срок заключения договора <span
                  class="c-c-danger">*</span></label>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <select v-model="createPurchaseData.pdm" class="c-input c-select">
                    <option value="">Месяц</option>
                    <option :value="i"
                            :key="i" v-for="i in shared.strDigMonths()">{{ i }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <select v-model="createPurchaseData.pdy" class="c-input c-select">
                    <option value="">Год</option>
                    <option :value="i"
                            :key="i" v-for="i in [
                                                    moment().toDate().getFullYear(),
                                                    moment().toDate().getFullYear()+1,
                                                    moment().toDate().getFullYear()+2
                                                    ]">{{ i }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Срок исполнения договора <span class="c-c-danger">*</span></label>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <select v-model="createPurchaseData.edm" class="c-input c-select">
                    <option value="">Месяц</option>
                    <option :value="i"
                            :key="i" v-for="i in shared.strDigMonths()">{{ i }}
                    </option>
                  </select>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                  <select v-model="createPurchaseData.edy" class="c-input c-select">
                    <option value="">Год</option>
                    <option :value="i"
                            :key="i" v-for="i in [
                                                    moment().toDate().getFullYear(),
                                                    moment().toDate().getFullYear()+1,
                                                    moment().toDate().getFullYear()+2,
                                                    moment().toDate().getFullYear()+3,
                                                    moment().toDate().getFullYear()+4,
                                                    moment().toDate().getFullYear()+5,
                                                    moment().toDate().getFullYear()+6
                                                  ]">{{ i }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!--
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Способ закупки <span class="c-c-danger">*</span></label>
              <select v-model="createPurchaseData.UF_PM_ID" class="c-input c-select">
                <option></option>
                <option :value="i.ID"
                        :key="i.ID" v-for="i in store.state.directory.simple.purchase_plan_methods">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
            -->
            <div class="df-fc modal-grid-row" v-if="list.meta.IS_CAN_CREATE_WITH_CUSTOM_CREATED_BY">
              <label for="" class="form-label">Инициатор закупки <span class="c-c-danger">*</span></label>
              <select v-model="createPurchaseData.UF_CREATED_BY" class="c-input c-select">
                <option></option>
                <option :value="i.ID"
                        :key="i.ID" v-for="i in list.meta.CREATED_BY_LIST">
                  {{ i.FULL_NAME }}
                </option>
              </select>
            </div>

            <div class="df-fc modal-text">
              <p>Для включения заявки в план закупок необходимо в созданной карточке закупки спланировать
                расходы по годам и направить ее на согласование</p>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-purchase-plan')">
            отменить
          </button>
          <button type="button" class="btn btn-blue" :disabled="createDisable" @click="createPurchase">создать</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Создание закупки в план -->
  <!-- Удалить запись -->
  <div class="modal" id="modal-purchase-plan-delete" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удалить закупку в плане</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#modal-purchase-plan-delete')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-text">
              <p>Вы действительно хотите удалить закупку в плане?</p>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#modal-purchase-plan-delete')">Нет
          </button>
          <button type="button" class="btn btn-red" @click="deletePlanCard()">Да</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Удалить запись -->

  <div class="modal" id="make-file" tabindex="-1">
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Сформировать файл</h5>
          <button type="button" class="btn-close" @click="shared.closeModal('#make-file')"
                  aria-label="Закрыть"></button>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Выбор отчета <span class="c-c-danger">*</span></label>
              <select v-model="reportData.ID" class="c-input c-select">
                <option :value="i.ID"
                        :key="i.ID" v-for="i in list.meta.REPORT_LIST">
                  {{ i.UF_NAME }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Формат выгрузки <span class="c-c-danger">*</span></label>
              <select v-model="reportData.FORMAT" class="c-input c-select">
                <option :value="type"
                        :key="type"
                        v-for="(i, type) in list.meta.REPORT_LIST.find((el) => el.ID === reportData.ID).FORMATS">
                  {{ i }}
                </option>
              </select>
            </div>
            <div class="df-fc modal-grid-row">
              <label for="" class="form-label">Год <span class="c-c-danger">*</span></label>
              <select v-model="reportData.UF_YEAR" class="c-input c-select">
                <option :value="i"
                        :key="i"
                        v-for="i in shared.yearList(moment().toDate().getFullYear()-1, moment().toDate().getFullYear()+1)">
                  {{ i }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-reset" @click="shared.closeModal('#make-file')">отменить
          </button>
          <button type="button" class="btn btn-blue" @click="exportExcel(1)">
            Сформировать
          </button>
        </div>
      </div>
    </div>
  </div>

</template>
